
export const shareQQ = (data) => {
  //分享的网址
  let url = data?.url || window.location.href
  // 分享标题
  let title = data?.title || document.getElementsByTagName('title')[0].innerHTML
  // 你的分享图片地址
  let img = data?.img || 'https://jy.gxb.cn//static/img/sales.47ded6a6.png'
  // 你的分享描述
  let summary = data?.summary || '钢,钢材,钢信,钢信通,钢信宝,钢材今天价格,不锈钢, 钢铁在线交易,钢铁线上交易,钢材期货,钢材期货交易,钢材走势,钢铁价格走势,螺纹钢现货价格,不锈钢现货价格'
  // 你的分享简述
  let desc = data?.desc || ''
  window.open(`http://connect.qq.com/widget/shareqq/index.html?url=${url}&title=${title}&pics=${img}&summary=${summary}&desc=${desc}`)
}


function shareToQq(title, url, picurl, desc) {

  var shareqqzonestring =
    'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?summary=' + title + '&url=' + url + '&pics=' + picurl + '&desc=' + desc;

  window.open(shareqqzonestring, 'newwindow', 'height=400,width=400,top=100,left=100');
}


// 分享到qq空间
export function subinfo(params) {
  let { title, url, pic, desc } = params
  title = title || "钢信宝"
  url = url || "bxgb2b.com"
  pic = pic || "https://jy.gxb.cn//static/img/sales.47ded6a6.png"
  shareToQq(title, url, pic, desc);
}




// 分享到新浪微博
export function shareToXl(title, url, picurl) {
  title = title || '钢信宝'
  url = url || 'gxb.cn'
  picurl = picurl || 'https://jy.gxb.cn//static/img/sales.47ded6a6.png'
  var sharesinastring =
    'https://service.weibo.com/share/share.php?title=' + title + '&url=' + url + '&content=utf-8&sourceUrl=' + url + '&pic=' + picurl;
  window.open(sharesinastring, 'newwindow', 'height=400,width=400,top=100,left=100');

}

