import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20f08abe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail_box" }
const _hoisted_2 = { class: "detail_left_box" }
const _hoisted_3 = { class: "detail_content_box" }
const _hoisted_4 = { class: "detail_right_box" }

import detailLeftVue from "./detailLeft/detailLeft.vue"; // 左侧内容
import detailCentent from "./detailContent/detailContent.vue"; // 中间内容
import detailRight from "./detailRight/detailRight.vue"; // 右边部分
import { getDetailNews } from "@/api/modules/news_api.js";
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";

export default {
  setup(__props) {

const route = useRoute();
const router = useRouter();
const data = reactive({
  // 改条商机的详情页
  info: {},
});

const loading = ref(false);
// 点击其他商机详情页
const readMoreNews = async (id) => {
  loading.value = true;
  let res = await getDetailNews({ id: id });
  if (res.status == 1) {
    data.info = res.data.newsInfo;
    const { goods, goodsImg } = res.data.newsInfo;
    goods.forEach((v, i) => {
      if (!v.goods_img && goodsImg?.length) {
        v.goods_img = goodsImg[i]["图片"];
      }
    });
  }
  loading.value = false;
};
// 点击了更多
const readMore = (flag) => {
  switch (flag) {
    case 0:
      // 点击的他的商机 跳转到他的商机
      sessionStorage.setItem("detail_current_nav", "贸易商机");
      break;
    case 1:
      sessionStorage.setItem("detail_current_nav", "最新报价");
      // 点击了他的热卖，跳转到更多报价
      break;
  }
  let { href } = router.resolve(
    `/${data.info?.user.company.id}/company/detail`
  );

  window.open(href, "_blank");
};
// API request
onMounted(async () => {
  loading.value = true;
  let res = await getDetailNews({ id: route.params.id });
  if (res.status === 1) {
    const { goods, goodsImg } = res.data.newsInfo;
    goods.forEach((v, i) => {
      if (!v.goods_img && goodsImg?.length) {
        v.goods_img = goodsImg[i]["图片"];
      }
    });
    res.data.newsInfo.content = res.data.newsInfo.content?.replace(
      /valign="top"/gi,
      (a) => {
        return ' valign="center"';
      }
    );
    data.info = res.data.newsInfo;
  }
  loading.value = false;
});

return (_ctx, _cache) => {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(detailLeftVue, {
        info: _unref(data).info
      }, null, 8, ["info"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(detailCentent, {
        info: _unref(data).info
      }, null, 8, ["info"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(detailRight, {
        info: _unref(data).info,
        onReadMoreNews: readMoreNews,
        onReadMore: readMore
      }, null, 8, ["info"])
    ])
  ])), [
    [_directive_loading, loading.value]
  ])
}
}

}